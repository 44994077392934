import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useStateContext } from 'provider/StateProvider'
import { Title } from 'components/Title'
import { Timeline } from 'components/Timeline'
import { ActionButton } from 'components/ActionButton'
import { DndList } from 'components/DndList'
import { HouseInput } from 'components/HouseInput'
import { Space } from 'components/Space'
import axios from 'axios'


export const TimelineConfirm = () => {
  const navigate = useNavigate()
  const state = useStateContext()
  const location = useLocation()

  const canEdit = location.pathname !== '/timeline-simple'

  React.useEffect(() => {
    if (state.area === '' || state.city === '') {
      navigate('/')
    }
  }, [])

  const goNext = (): void => {
    const cityArea = state.city + state.area
    if (state.cityAreaCounter !== cityArea) {
      const baseurl = process.env.REACT_APP_API_URL
      const params = {
        city: state.city,
        area: state.area
      }
      axios.post(`${baseurl}/api/city-area-counter`, params)
      state.setCityAreaCounter(cityArea)
    }
    navigate('/timeline-output')
  }

  return (
    <div>
      { canEdit && (
        <>
          <Title text='マイ・タイムライン確認' />
          <Timeline process={4} />
          <h2>これまでに入力・並び替えた避難行動を確認し、完成させましょう</h2>
        </>
      ) }
      <HouseInput canEdit={canEdit} />

      <DndList state={state} canEdit={canEdit} canDelete={false} />

      <Space height={10} />

      { canEdit && <ActionButton text='次へ' color='primary' disabled={state.house === ''} onClick={goNext} /> }
      <ActionButton text='戻る' color='secondary' onClick={() => navigate(-1)} />
    </div>
  )
}
