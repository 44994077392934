import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RadioGroup, Radio, TextField, FormControlLabel, FormControl, Checkbox, FormGroup, FormHelperText, Typography } from '@mui/material'
import { useStateContext } from 'provider/StateProvider'
import { Title } from 'components/Title'
import { Timeline } from 'components/Timeline'
import { ActionButton } from 'components/ActionButton'
import { Question } from 'components/Question'
import { kawamirugunmaUrl } from 'common/consts'
import { Box } from '@mui/system'
import * as utils from 'common/utils'


export const DestPlace = () => {
  const navigate = useNavigate()
  const state = useStateContext()

  React.useEffect(() => {
    if (state.area === '' || state.city === '') {
      navigate('/')
    } else if (state.risks === undefined) {
      navigate('/risk-map')
    }
    return () => {
      window.removeEventListener('message', loadShelter)
    }
  }, [])

  const othersItemChange = (item: string, checked: boolean) => {
    let arr = state.othersItems.concat()
    if (checked === true) {
      arr.push(item)
    } else {
      arr = arr.filter(v => v !== item)
    }
    state.setOthersItems(arr)
  }

  const OthersItems = () => {
    if (state.isWithOthers !== true) {
      return <></>
    }
    const items = [
      '高齢者',
      '障がい者',
      '乳児・幼児',
      '妊婦',
      'ペット'
    ]
    return (
      <FormControl sx={{mb: 2, maxWidth: 430}}>
        <FormHelperText sx={{color: 'red'}}>避難支援対象を下記より選択してください。</FormHelperText>
        <FormGroup row sx={{paddingLeft: 'auto'}}>
          {
            items.map((item, i) => {
              return (<FormControlLabel
                sx={{minWidth: '123px', marginLeft: '3px'}}
                key={i}
                control={<Checkbox checked={state.othersItems.includes(item)} onChange={(e) => othersItemChange(item, e.target.checked)} />}
                label={item}
              />)
            })
          }
        </FormGroup>
      </FormControl>
    )
  }

  const isNextDisabled = (): boolean => {
    if (state.isWithOthers === true && state.othersItems.length === 0) {
      return true
    }
    return state.destination === '' || state.destWay === '' || state.destTime === ''
  }

  const loadShelter = (e: MessageEvent) => {
    if (e.origin !== kawamirugunmaUrl) {
      return
    }
    window.removeEventListener('message', loadShelter)
    if (e.data) {
      console.log(e.data)
      state.setDestination(e.data.name)
      state.setDestinationLatLng([e.data.lat, e.data.lng])
    }
  }

  const showKawamiru = () => {
    if (utils.isDevelopment() === true) {
      return
    }
    window.addEventListener('message', loadShelter)
    let gps = '&lat=36.3912157951864&lng=139.060971736908'
    if (state.riskLatLng !== null) {
      gps = `&lat=${state.riskLatLng[0]}&lng=${state.riskLatLng[1]}`
    } else {
      const latLng = utils.getLatLng(state.city, state.area)
      if (latLng !== undefined) {
        gps = `&lat=${latLng.lat}&lng=${latLng.lng}`
      }
    }

    const url = `${kawamirugunmaUrl}?openExternalBrowser=1&zoom=15&risk=none&tile=gray&shelter=1&hazardmaps=shinsuishin_kuni_data&mytimeline_shelter=1${gps}`
    const openUrl = (url: string) => {
      const w = window.open(url)
      if (w !== null) {
        state.setKawamiruWindow(w)
      }
    }
    if (state.kawamiruWindow === null || state.kawamiruWindow.closed === true) {
      openUrl(url)
    } else {
      state.kawamiruWindow.close()
      setTimeout(() => {
        openUrl(url)
      }, 500)
    }
  }

  return (
    <div>
      <Title text='家庭状況の確認' />
      <Timeline process={2} />

      <Question text={'ご自身または一緒に避難する方で避難に支援が必要な人やペットはいますか？'} />
      <FormControl sx={{mb: 2}}>
        <RadioGroup
          row
          value={state.isWithOthers ? '1' : '0'}
          onChange={(e) => state.setIsWithOthers(e.target.value === '1')}
        >
          <FormControlLabel value="1" control={<Radio />} label="はい" />
          <FormControlLabel value="0" control={<Radio />} label="いいえ" />
        </RadioGroup>
      </FormControl>

      <Box sx={{mb: 2}}>
        { state.isWithOthers && <Typography variant='body2' fontWeight={'bold'} whiteSpace={'pre'}>{'あなたの避難のタイミングは\n「警戒レベル３高齢者等避難」です。'}</Typography> }
        { !state.isWithOthers && <Typography variant='body2' fontWeight={'bold'} whiteSpace={'pre'}>{'あなたの避難のタイミングは\n「警戒レベル４避難指示」です。'}</Typography> }
      </Box>

      <div>
        <OthersItems />
      </div>

      <Question text={'安全な場所に住んでいて身を寄せられる親戚や知人はいますか？'} />
      <FormControl sx={{mb: 4}}>
        <RadioGroup
          row
          value={state.isSafePlace ? '1' : '0'}
          onChange={(e) => state.setIsSafePlace(e.target.value === '1')}
        >
          <FormControlLabel value="1" control={<Radio />} label="はい" />
          <FormControlLabel value="0" control={<Radio />} label="いいえ" />
        </RadioGroup>
      </FormControl>

      <Box sx={{width: 260, mx: 'auto'}}>
        <TextField
          label="避難先 (必須)"
          placeholder={ state.isSafePlace ? '◯◯宅' : '◯◯避難所' }
          variant="outlined"
          size="small"
          fullWidth
          value={state.destination}
          onChange={e => state.setDestination(e.target.value)}
        />

        { state.isSafePlace === false && (
          <Box>
            <ActionButton text={'地図から避難所を選択する'} color='primary' onClick={showKawamiru} />
          </Box>
        ) }

        <TextField
          label="移動手段 (必須)"
          placeholder='車・自転車・徒歩など'
          variant="outlined"
          size="small"
          fullWidth
          sx={{my: 2}}
          value={state.destWay}
          onChange={e => state.setDestWay(e.target.value)}
        />

        <TextField
          label="移動時間 (必須)"
          placeholder='30分・1時間30分など'
          variant="outlined"
          size="small"
          fullWidth
          sx={{mb: 2}}
          value={state.destTime}
          onChange={e => state.setDestTime(e.target.value)}
        />
      </Box>

      <div>
        <ActionButton text='次へ' color='primary' disabled={isNextDisabled()} onClick={() => navigate('/dest-confirm')} />
        <ActionButton text='戻る' color='secondary' onClick={() => navigate(-1)} />
      </div>
    </div>
  )
}
