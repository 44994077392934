import React from 'react'
import { Paper, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useLocation } from 'react-router-dom'
import { DndListItemType } from 'common/types'
import { useStateContext } from 'provider/StateProvider'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'

type DndItemProps = {
  text: string
  isUp: boolean
  isDown: boolean
  onUpDownEvent?: () => void
  item?: DndListItemType
  isDragging?: boolean
  canDelete?: boolean
  onDelete?: () => void
}

export const DndItem = ({text, isUp, isDown, item=undefined, isDragging=false, canDelete=false, onDelete=()=>{}}: DndItemProps) => {
  const state = useStateContext()
  const location = useLocation()

  const move = (event: 'up' | 'down', item?: DndListItemType) => {
    if (item === undefined) {
      return
    }
    const target = Object.assign({}, item)
    if (item.category === 'goods') {
      if (target.risk === 0 && event === 'down') {
        target.risk = 1
        state.setGoodsBaseItems(state.goodsBaseItems.filter(i => i.id !== item.id))
        state.setGoodsItems(state.goodsItems.concat(target))
      } else if (target.risk === 1) {
        const targetIndex = state.goodsItems.findIndex(i => i.id === target.id)
        if (targetIndex === 0 && event === 'up') {
          target.risk = 0
          state.setGoodsItems(state.goodsItems.filter(i => i.id !== item.id))
          state.setGoodsBaseItems(state.goodsBaseItems.concat(target))
        } else {
          if (targetIndex >= state.goodsItems.length - 1 && event == 'down') {
            return
          }
          const goods = [...state.goodsItems]
          const [removed] = goods.splice(targetIndex, 1)
          if (event == 'up') {
            goods.splice(targetIndex - 1, 0, removed)
          } else if (event === 'down') {
            goods.splice(targetIndex + 1, 0, removed)
          }
          state.setGoodsItems(goods)
        }
      }
    } else {
      if (target.risk === 0) {
        if (event === 'down') {
          updateActionItemRisk(target.id, 1)
        }
      } else {
        const targetCategory = location.pathname === '/action-base' ? ['base', 'destination'] : ['base', 'case', 'destination']
        const riskItems = state.actionItems.filter(i => targetCategory.includes(i.category) && i.risk === target.risk)
        const targetIndex = riskItems.findIndex(i => i.id === target.id)
        if (targetIndex < 0) {
          return
        }
        if (event === 'down' && targetIndex >= riskItems.length - 1) {
          if (target.risk === 1) {
            updateActionItemRisk(target.id, 2)
          } else if (target.risk === 2) {
            updateActionItemRisk(target.id, 3)
          } else if (target.risk === 3) {
            updateActionItemRisk(target.id, 4)
          }
        } else if (event === 'up' && targetIndex === 0) {
          if (target.risk === 1 && target.category === 'destination') {
            return
          }
          if (target.risk === 1 && target.category === 'base' && location.pathname === '/action-case') {
            return
          }
          if (target.risk === 1 && location.pathname === '/timeline-confirm') {
            return
          }
          updateActionItemRisk(target.id, target.risk === 2 ? 1 : target.risk === 3 ? 2 : target.risk === 4 ? 3 :0)
        } else {
          // 並び順を保持しつつriskで並び替え
          const risks = [0, 1, 2, 3, 4]
          let items: DndListItemType[] = []
          risks.forEach(risk => {
            items = items.concat([...state.actionItems.filter(i => i.risk === risk)])
          })
          const actionItemIndex = items.findIndex(i => i.id === target.id)
          const [removed] = items.splice(actionItemIndex, 1)
          if (event == 'up') {
            items.splice(actionItemIndex - 1, 0, removed)
          } else if (event === 'down') {
            items.splice(actionItemIndex + 1, 0, removed)
          }
          state.setActionItems(items)
        }
      }
    }
  }

  const updateActionItemRisk = (id: number, risk: 0 | 1 | 2 | 3 | 4) => {
    const items = [...state.actionItems]
    const finded = items.find(i => i.id === id)
    if (finded !== undefined) {
      finded.risk = risk
      state.setActionItems(items)
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{
        my: 1,
        pl: '5px',
        pr: '3px',
        display: 'flex',
        backgroundColor: isDragging ? '#f1f8e9' : '#ffffff',
        border: isDragging ? '2px dashed #23cd63' : '2px solid #23cd63'
      }}
    >
      { isDown && <SouthIcon color='success' sx={{py: '12px', width: 36, height: 50, alignItems: 'center', display: 'flex'}} onClick={() => move('down', item)} /> }
      { isUp && <NorthIcon color='success' sx={{py: '12px', width: 36, height: 50, alignItems: 'center', display: 'flex'}} onClick={() => move('up', item)} /> }
      <Typography sx={{textAlign: 'left', pl: '5px', height: 50, alignItems: 'center', display: 'flex' }} variant='body2'>{text}</Typography>
      { canDelete && <DeleteIcon color='error' sx={{ml: 'auto', padding: '1px', opacity: 0.7, height: 50, alignItems: 'center', display: 'flex'}} onClick={onDelete} /> }
    </Paper>
  )
}
