import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Typography, Paper } from '@mui/material'
import { useStateContext } from 'provider/StateProvider'
import { Title } from 'components/Title'
import { Timeline } from 'components/Timeline'
import { ActionButton } from 'components/ActionButton'
import * as utils from 'common/utils'
import { kawamirugunmaUrl } from 'common/consts'


export const RiskMap = () => {
  const state = useStateContext()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (state.area === '' || state.city === '') {
      navigate('/')
    }
    return () => {
      window.removeEventListener('message', loadRisk)
    }
  }, [])

  const loadRisk = (e: MessageEvent) => {
    if (e.origin !== kawamirugunmaUrl) {
      return
    }
    window.removeEventListener('message', loadRisk)
    console.log(e.data)
    state.setRisks(e.data.contents)
    state.setRiskLatLng([e.data.lat, e.data.lng])
    navigate('/risk-confirm')
  }

  const showKawamiru = () => {
    if (utils.isDevelopment() === true) {
      state.setTestRisks()
      navigate('/risk-confirm')
      return
    }
    window.addEventListener('message', loadRisk)
    let gps = '&lat=36.3912157951864&lng=139.060971736908'
    const latLng = utils.getLatLng(state.city, state.area)
    if (latLng !== undefined) {
      gps = `&lat=${latLng.lat}&lng=${latLng.lng}`
    }

    const hazardmaps = '&hazardmaps=shinsuishin_kuni_data,dosyasaigai_keikaikuiki,kaokutoukai_hanran_kuni_data,kaokutoukai_kagan_kuni_data'
    const url = `${kawamirugunmaUrl}?openExternalBrowser=1&zoom=15&risk=none&tile=gray&bulkHazard=1&mytimeline=1&hazardLegends=0${hazardmaps}${gps}`
    const openUrl = (url: string) => {
      const w = window.open(url)
      if (w !== null) {
        state.setKawamiruWindow(w)
      }
    }
    if (state.kawamiruWindow === null || state.kawamiruWindow.closed === true) {
      openUrl(url)
    } else {
      state.kawamiruWindow.close()
      setTimeout(() => {
        openUrl(url)
      }, 500)
    }
  }

  return (
    <div>
      <Title text='自宅の洪水・土砂災害リスクの確認' />
      <Timeline process={1} />
      <Card sx={{ minWidth: 260, width: 260, mx: 'auto', mb: 5, p: 1 }}>
        <Typography variant='caption' color='GrayText'>
          選択した市町村・地区
        </Typography>
        <Typography variant="h5" component="div">
          { state.city }
        </Typography>
        <Typography variant="h6" component="div">
          { state.area }
        </Typography>
      </Card>
      <Paper elevation={0} sx={{backgroundColor: 'transparent', mb: 3, whiteSpace: 'pre'}}>
        {'下のボタンで地図が開きます。\n自宅の場所を選択(タップ)してください。​'}
      </Paper>
      <ActionButton text='このボタンを押して​​' text2='洪水・土砂災害リスクを把握する' color='primary' onClick={showKawamiru} />

      <ActionButton text='戻る' color='secondary' mt={3} onClick={() => navigate(-1)} />
    </div>
  )
}
