import React from 'react'
import './Header.scss'
import header_title1 from 'assets/img/header_title1.png'
import header_title2 from 'assets/img/header_title2.png'
import header_photo from 'assets/img/header_photo.png'


export const Header = () => {
  return (
    <header>
      <div className="border"></div>
      <div className="header-inner">
        <div className='title1'><img src={header_title1} /></div>
        <div className='title2'><img src={header_title2} /></div>
        <div className="photo"><img src={header_photo} /></div>
      </div>
      <div className="gunma">群馬県</div>
      <div className="border"></div>
    </header>
  )
}
