import cityAreaJson from 'assets/json/city_area.json'


export const getCities = (): string[] => {
  return Array.from(new Set(cityAreaJson.map(r => r.city)))
}


const getKanaPrefix = (c: string): string => {
  if ('アイウエオ'.includes(c)) {
    return 'あ'
  } else if ('カキクケコガギグゲゴ'.includes(c)) {
    return 'か'
  } else if ('サシスセソザジズゼゾ'.includes(c)) {
    return 'さ'
  } else if ('タチツテトダヂヅデド'.includes(c)) {
    return 'た'
  } else if ('ナニヌネノ'.includes(c)) {
    return 'な'
  } else if ('ハヒフヘホバビブベボパピプペポ'.includes(c)) {
    return 'は'
  } else if ('マミムメモ'.includes(c)) {
    return 'ま'
  } else if ('ヤユヨ'.includes(c)) {
    return 'や'
  } else if ('ラリルレロ'.includes(c)) {
    return 'ら'
  } else if ('ワヲン'.includes(c)) {
    return 'わ'
  } else {
    return c
  }
}

export const getAreas = (city: string): {name: string; kana: string}[] => {
  const arr = cityAreaJson.filter(r => r.city === city).map(r => {
    return {
      name: r.area,
      kana: getKanaPrefix(r.kana[0])
    }
  })
  arr.sort((a, b) => {
    if (a.kana < b.kana) {
      return -1
    } else {
      return 1
    }
  })
  return arr
}

export const getLatLng = (city: string, area: string): {lat: number, lng: number} | undefined => {
  const finded = cityAreaJson.find(r => r.city === city && r.area === area)
  if (finded == undefined) {
    return undefined
  } else {
    return {
      lat: finded.lat,
      lng: finded.lng
    }
  }
}

export const isDevelopment = () => {
  return process.env.NODE_ENV === 'development'
}

export const getSinsuiDisplayValue = (value: string) => {
  if (!value) {
    return ''
  } else if (value === '~0.5m') {
    return `${value} (1階床下)`
  } else if (value === '0.5m~3.0m') {
    return `${value} (1階浸水程度)`
  } else if (value === '3.0m~5.0m') {
    return `${value} (2階浸水程度)`
  } else {
    return `${value} (3階以上浸水程度)`
  }
}
