import React from 'react'

import './App.scss'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { Header } from 'components/Header'
import { HeaderMini } from 'components/HeaderMini'
import { ScrollTop } from 'components/ScrollTop'
import { StateProvider } from 'provider/StateProvider'
import { Top } from 'pages/Top'
import { RiskArea } from 'pages/RiskArea'
import { RiskMap } from 'pages/RiskMap'
import { DestFlood } from 'pages/DestFlood'
import { DestPlace } from 'pages/DestPlace'
import { DestConfirm } from 'pages/DestConfirm'
import { ActionBase } from 'pages/ActionBase'
import { ActionGoods } from 'pages/ActionGoods'
import { ActionCase } from 'pages/ActionCase'
import { TimelineConfirm } from 'pages/TimelineConfirm'
import { TimelineOutput } from 'pages/TimelineOutput'
import { TimelineSimple } from 'pages/TimelineSimple'
import { RiskConfirm } from 'pages/RiskConfirm'
import { ActionObservatory } from 'pages/ActionObservatory'
import { CityAreaCounter } from 'pages/CityAreaCounter'


export const App = () => {
  // redirect
  const location = useLocation()
  if (location.pathname === '/top') {
    return <Navigate to="/" replace />
  }

  return (
    <div className='app'>
      { location.pathname === '/' ? <Header /> : location.pathname !== '/timeline-simple' && <HeaderMini /> }
      <div className='content'>
        <StateProvider>
          <ScrollTop />
          <Routes>
            <Route path="/" element={ <Top /> } />
            <Route path="/city-area-counter" element={ <CityAreaCounter /> } />
            <Route path="/risk-area" element={ <RiskArea /> } />
            <Route path="/risk-map" element={ <RiskMap /> } />
            <Route path="/risk-confirm" element={ <RiskConfirm /> } />
            <Route path="/dest-flood" element={ <DestFlood /> } />
            <Route path="/dest-place" element={ <DestPlace /> } />
            <Route path="/dest-confirm" element={ <DestConfirm /> } />
            <Route path="/action-base" element={ <ActionBase /> } />
            <Route path="/action-goods" element={ <ActionGoods /> } />
            <Route path="/action-case" element={ <ActionCase /> } />
            <Route path="/action-observatory" element={ <ActionObservatory /> } />
            <Route path="/timeline-confirm" element={ <TimelineConfirm /> } />
            <Route path="/timeline-output" element={ <TimelineOutput /> } />
            <Route path="/timeline-simple" element={ <TimelineSimple /> } />
            <Route path="*" element={ <Navigate to="/" replace />} />
          </Routes>
        </StateProvider>
      </div>
      <div className="border"></div>
    </div>
  )
}
