import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useStateContext } from 'provider/StateProvider'
import { Title } from 'components/Title'
import { Timeline } from 'components/Timeline'
import { ActionButton } from 'components/ActionButton'
import axios from 'axios'
import * as utils from 'common/utils'
import { isAndroid, isSafari, isIOS } from 'react-device-detect'


export const TimelineOutput = () => {
  const navigate = useNavigate()
  const state = useStateContext()

  const [pdfUrl, setPdfUrl] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [downloading, setDownLoading] = React.useState(false)

  React.useEffect(() => {
    if (state.area === '' || state.city === '') {
      navigate('/')
    }
  }, [])

  const previewPdf = async (url: string) => {
    if (isAndroid) {
      window.open(`https://docs.google.com/gview?url=${url}&embedded=true`, '_blank')
    } else {
      window.open(url)
    }
  }

  const downloadPdf = async (url: string) => {
    if (!url) {
      return
    }
    const response = await fetch(url)
    if (!response.ok) {
      alert('ダウンロードに失敗しました')
    }
    const blob = await response.blob()
    const blobUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = blobUrl
    a.download = 'mytimeline'
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(blobUrl)
  }

  const output = async (isDownload: boolean) => {
    if (pdfUrl) {
      if (isDownload === true) {
        downloadPdf(pdfUrl)
      } else {
        previewPdf(pdfUrl)
      }
      return
    }
    const risk1_items = state.actionItems.filter(item => item.risk === 1).map(item => item.text)
    const risk2_items = state.actionItems.filter(item => item.risk === 2).map(item => item.text)
    const risk3_items = state.actionItems.filter(item => item.risk === 3).map(item => item.text)
    const risk4_items = state.actionItems.filter(item => item.risk === 4).map(item => item.text)

    // risk1は最大6行、risk2は最大9行、risk3は最大5行
    if (risk1_items.length > 6) {
      const arr = risk1_items.splice(6, risk1_items.length - 6)
      risk2_items.unshift(...arr)
    }
    if (risk2_items.length > 9) {
      const arr = risk2_items.splice(9, risk2_items.length - 9)
      risk3_items.unshift(...arr)
    }
    if (risk3_items.length > 5) {
      const arr = risk3_items.splice(5, risk3_items.length - 5)
      risk4_items.unshift(...arr)
    }

    if (isDownload === true) {
      setDownLoading(true)
    } else {
      setLoading(true)
    }
    const baseurl = process.env.REACT_APP_API_URL
    const sinsuiRiskValue = state.getSinsuiRiskValue()
    const params: any = {
      city: state.city,
      area: state.area,
      house: state.house,
      observatory: state.observatory,
      destination: state.destination,
      dest_way: state.destWay,
      dest_time: state.destTime,
      sinsui_risk_value: utils.getSinsuiDisplayValue(sinsuiRiskValue),
      sinsui_time: state.getSinsuiRiskTime(),
      is_kaokutoukai_risk: state.hasKaokutoukaiRisk(),
      dosya_warning_level: state.getDosyaWarningLevel(),
      is_safe_place: state.isSafePlace,
      is_with_others: state.isWithOthers,
      others_items: state.othersItems,
      risk_level: state.getRiskLevel(),
      goods_items: state.goodsItems.map(item => item.text),
      risk1_items: risk1_items,
      risk2_items: risk2_items,
      risk3_items: risk3_items,
      risk4_items: risk4_items
    }
    if (state.riskLatLng !== null) {
      params['home_lat'] = state.riskLatLng[0]
      params['home_lng'] = state.riskLatLng[1]
    }
    if (state.destinationLatLng !== null) {
      params['dest_lat'] = state.destinationLatLng[0]
      params['dest_lng'] = state.destinationLatLng[1]
    }
    try {
      const res = await axios.post(`${baseurl}/api/output/pdf`, params)
      if (res !== null && res.data.url) {
        setPdfUrl(res.data.url)
        if (isDownload == true) {
          downloadPdf(res.data.url)
        } else {
          previewPdf(res.data.url)
        }
      }
    } catch {
      alert('PDF出力に失敗しました')
    } finally {
      if (isDownload === true) {
        setDownLoading(false)
      } else {
        setLoading(false)
      }
    }
  }

  const backToTop = () => {
    if (confirm('最初からやり直しますか？') === true) {
      navigate('/', { replace: true })
    }
  }

  const isIOSwithSafari = isIOS && isSafari

  return (
    <div>
      <Title text='マイ・タイムライン確認' />
      <Timeline process={5} />

      <Typography variant='body1' sx={{my: 2, fontWeight: 'bold'}}>マイ・タイムラインが完成しました！</Typography>

      <Typography variant='body2' sx={{fontWeight: 'bold'}}>いつでも見返せるように保存したり、</Typography>
      <Typography variant='body2' sx={{fontWeight: 'bold'}}>印刷しましょう。</Typography>

      <Typography variant='body2' sx={{mt: 5, fontWeight: 'bold'}}>マイ・タイムラインの編集</Typography>
      <ActionButton text='編集' color='primary' onClick={() => navigate('/risk-area')} />

      <Typography variant='body2' sx={{mt: 3, fontWeight: 'bold'}}>マイ・タイムラインシートの出力</Typography>
      { !isIOSwithSafari && <ActionButton text='PDF プレビュー　' color='primary' disabled={loading || downloading} loading={loading} onClick={() => output(false)} /> }
      <ActionButton text={ isIOSwithSafari ? 'PDF 表示' : 'PDF ダウンロード'} color='primary' disabled={loading || downloading} loading={downloading} onClick={() => output(true)} />

      <ActionButton text='戻る' color='secondary' onClick={() => navigate(-1)} mt={5} />
      <ActionButton text='最初に戻る' color='secondary' onClick={backToTop} mt={3} />
    </div>
  )
}
