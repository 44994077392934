import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from 'provider/StateProvider'
import { TimelineConfirm } from './TimelineConfirm'


export const TimelineSimple = () => {
  const navigate = useNavigate()
  const state = useStateContext()

  React.useEffect(() => {
    if (state.area === '' || state.city === '') {
      navigate('/')
    }
  }, [])

  return (
    <div>
      <TimelineConfirm />
    </div>
  )
}
