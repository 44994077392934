import React from 'react'
import { Paper } from '@mui/material'


type SpaceProps = {
  height?: number
}

export const Space = ({height=50}: SpaceProps) => {
  return (
    <Paper sx={{height: height, backgroundColor: 'transparent'}} elevation={0} />
  )
}
