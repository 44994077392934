import React from 'react'
import './HeaderMini.scss'
import header_title1 from 'assets/img/header_title1.png'
import header_title2 from 'assets/img/header_title2.png'


export const HeaderMini = () => {
  return (
    <div className="header-mini">
      <div className="border"></div>
      <div className="header-inner">
        <div className='title1'><img src={header_title1} /></div>
        <div className='title2'><img src={header_title2} /></div>
      </div>
      <div className="gunma">群馬県</div>
      <div className="border"></div>
    </div>
  )
}
