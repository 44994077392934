import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from 'provider/StateProvider'
import { Title } from 'components/Title'
import { Timeline } from 'components/Timeline'
import { ActionButton } from 'components/ActionButton'
import { RiskLabel } from 'components/RiskLabel'
import { Box } from '@mui/material'


export const DestConfirm = () => {
  const navigate = useNavigate()
  const state = useStateContext()

  React.useEffect(() => {
    if (state.area === '' || state.city === '') {
      navigate('/')
    }
  }, [])

  const ConfirmContent = () => {
    const level = state.getRiskLevel()
    if (level === 0) {
      return <></>
    } else {
      return <RiskLabel level={level} mx='auto' height='50px' />
    }
  }

  const getRefugeText = (): string => {
    if (state.getRiskLevel() === 0) {
      const sinsui = state.getSinsuiRiskValue()
      if (sinsui === '3.0m~5.0m') {
        return '台風・豪雨時は自宅の３階以上で安全を確保しよう'
      } else if (sinsui === '0.5m~3.0m') {
        return '台風・豪雨時は自宅の２階以上で安全を確保しよう'
      } else if (sinsui !== '') {
        return '台風・豪雨時は自宅のできるだけ高い所で安全を確保しよう'
      } else {
        return '台風・豪雨時は自宅に留まり安全を確保しよう'
      }
    }
    return `までに安全な${state.destination}に避難しよう`
  }

  const moveNext = () => {
    const items = [...state.actionItems]
    const targets = items.filter(item => item.category !== 'destination')
    const ids = targets.map(i => i.id)
    const appendId = ids.length === 0 ? 1 : Math.max(...ids) + 1
    if (state.getRiskLevel() <= 2) {
      targets.push({category: 'destination', risk: 3, id: appendId, text: '自宅で安全確保'})
    } else {
      const risk = state.getRiskLevel() as 3 | 4
      targets.push({category: 'destination', risk: risk, id: appendId, text: `${state.destination}へ避難開始 (${state.destWay}で${state.destTime})`})
      targets.push({category: 'destination', risk: risk, id: appendId + 1, text: '避難完了'})
    }
    state.setActionItems(targets)
    navigate('/action-base')
  }

  return (
    <div>
      <Title text='避難行動の確認' />
      <Timeline process={3} />

      <ConfirmContent />

      <Box mt={1}>
        { getRefugeText() }
      </Box>

      <ActionButton text='次へ' color='primary' onClick={moveNext} mt={5} />
      <ActionButton text='戻る' color='secondary' onClick={() => navigate(-1)} />
    </div>
  )
}
