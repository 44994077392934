import React from 'react'
import Paper from '@mui/material/Paper'
import { SxProps } from '@mui/material'

type QuestionProps = {
  text: string
  mt?: number
  mb?: number
}

export const Question = ({text, mt=2, mb=1}: QuestionProps) => {
  const sx: SxProps = {
    backgroundColor: '#cde4ff',
    color: '#333333',
    fontSize: 16,
    paddingX: 1,
    paddingY: 3,
    borderTop: 'solid 5px #5989cf',
    borderBottom: 'solid 5px #5989cf',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    mt: mt,
    mb: mb
  }
  return (
    <Paper elevation={0} sx={sx}>
      { text }
    </Paper>
  )
}
