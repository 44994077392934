import React from 'react'
import './HouseInput.scss'
import { FormControl, Paper, TextField } from '@mui/material'
import { useStateContext } from 'provider/StateProvider'


type MouseInputProps = {
  canEdit: boolean
}

export const HouseInput = ({canEdit}: MouseInputProps) => {
  const state = useStateContext()
  return (
    <div className='house-input'>
      <Paper elevation={0} sx={{display: 'flex', width: '100%', mb: 1}}>
        { canEdit && (
          <FormControl sx={{width: 'calc(100% - 80px)'}}>
            <TextField
              placeholder='◯◯◯'
              inputProps={{maxLength: 12}}
              fullWidth
              variant="standard"
              value={state.house}
              onChange={(e) => state.setHouse(e.target.value)}
            />
          </FormControl>
        ) }
        { !canEdit && state.house }
        <Paper elevation={0} sx={{width: '80px'}}><h5>家の</h5></Paper>
      </Paper>
      <h5>マイ・タイムライン</h5>
    </div>
  )
}
