import React from 'react'
import { DndListItemType } from 'common/types'
import * as utils from 'common/utils'


type StateProps = {
  children: React.ReactNode
}

type RiskRow = {
  name: string
  value: string
}

export type StateContextType = {
  city: string
  setCity: React.Dispatch <React.SetStateAction<string>>

  area: string
  setArea: React.Dispatch <React.SetStateAction<string>>

  cityAreaCounter: string
  setCityAreaCounter: React.Dispatch <React.SetStateAction<string>>

  risks: RiskRow[] | undefined
  setRisks: React.Dispatch <React.SetStateAction<RiskRow[] | undefined>>

  floor: number
  setFloor: React.Dispatch <React.SetStateAction<number>>

  isPatience: boolean
  setIsPatience: React.Dispatch <React.SetStateAction<boolean>>

  isWithOthers: boolean
  setIsWithOthers: React.Dispatch <React.SetStateAction<boolean>>

  othersItems: string[]
  setOthersItems: React.Dispatch <React.SetStateAction<string[]>>

  isSafePlace: boolean
  setIsSafePlace: React.Dispatch <React.SetStateAction<boolean>>

  observatories: string[]
  setObservatories: React.Dispatch <React.SetStateAction<string[]>>

  observatory: string
  setObservatory: React.Dispatch <React.SetStateAction<string>>

  destination: string
  setDestination: React.Dispatch <React.SetStateAction<string>>

  destWay: string
  setDestWay: React.Dispatch <React.SetStateAction<string>>

  destTime: string
  setDestTime: React.Dispatch <React.SetStateAction<string>>

  actionItems: DndListItemType[]
  setActionItems: React.Dispatch <React.SetStateAction<DndListItemType[]>>

  goodsBaseItems: DndListItemType[]
  setGoodsBaseItems: React.Dispatch <React.SetStateAction<DndListItemType[]>>

  goodsItems: DndListItemType[]
  setGoodsItems: React.Dispatch <React.SetStateAction<DndListItemType[]>>

  house: string
  setHouse: React.Dispatch <React.SetStateAction<string>>

  kawamiruWindow: Window | null
  setKawamiruWindow: React.Dispatch <React.SetStateAction<Window | null>>

  riskLatLng: [number, number] | null
  setRiskLatLng: React.Dispatch <React.SetStateAction<[number, number] | null>>

  destinationLatLng: [number, number] | null
  setDestinationLatLng: React.Dispatch <React.SetStateAction<[number, number] | null>>

  // functions
  getSinsuiRiskValue: () => string
  getSinsuiRiskLevel: () => number
  getSinsuiRiskTime: () => string
  hasRisk: () => boolean
  hasKaokutoukaiRisk: () => boolean
  getDosyaWarningLevel: () => number
  getRiskLevel: () => number
  setTestRisks: () => void
}

const defaultActionItems: DndListItemType[] = [
  { category: 'base', risk: 0, id: 1, text: '今後の台風を確認する' },
  { category: 'base', risk: 0, id: 2, text: '川の水位や土砂災害の情報を確認する' },
  { category: 'base', risk: 0, id: 3, text: '住んでいるところと上流の雨量を確認する' },
  { category: 'base', risk: 0, id: 4, text: '避難所への持ち物を準備する' },
  { category: 'base', risk: 0, id: 5, text: '避難しやすい服装に着替える' },
  { category: 'case', risk: 0, id: 6, text: '持病の薬を病院にもらいに行く' },
  { category: 'case', risk: 0, id: 7, text: '避難に支援が必要な人の準備を手伝う' },
  { category: 'case', risk: 0, id: 8, text: '貴重品を高い所に上げる' },
  { category: 'case', risk: 0, id: 9, text: '家族や知人に連絡する' },
  { category: 'case', risk: 0, id: 10, text: '近所へ声をかける' }
]

const defaultGoodsItems: DndListItemType[] = [
  { category: 'goods', risk: 0, id: 1, text: '持病の薬' },
  { category: 'goods', risk: 0, id: 2, text: 'スマートフォン・携帯電話' },
  { category: 'goods', risk: 0, id: 3, text: '充電器' },
  { category: 'goods', risk: 0, id: 4, text: 'メガネ' },
  { category: 'goods', risk: 0, id: 5, text: 'マスク' },
  { category: 'goods', risk: 0, id: 6, text: '現金' },
  { category: 'goods', risk: 0, id: 7, text: '食料' }
]

const StateContext = React.createContext<StateContextType | undefined>(undefined)

export const StateProvider = ({children}: StateProps) => {
  const [city, setCity] = React.useState('')
  const [area, setArea] = React.useState('')
  const [cityAreaCounter, setCityAreaCounter] = React.useState(utils.isDevelopment() === true ? '前橋市青葉町' : '')
  const [risks, setRisks] = React.useState<RiskRow[] | undefined>(undefined)
  const [floor, setFloor] = React.useState<number>(utils.isDevelopment() === true ? 2 : 0)
  const [isPatience, setIsPatience] = React.useState<boolean>(false)
  const [isWithOthers, setIsWithOthers] = React.useState<boolean>(false)
  const [othersItems, setOthersItems] = React.useState<string[]>([])
  const [isSafePlace, setIsSafePlace] = React.useState<boolean>(false)
  const [observatories, setObservatories] = React.useState<string[]>([])
  const [observatory, setObservatory] = React.useState('')
  const [destination, setDestination] = React.useState(utils.isDevelopment() === true ? 'テスト避難所' : '')
  const [destWay, setDestWay] = React.useState(utils.isDevelopment() === true ? '徒歩' : '')
  const [destTime, setDestTime] = React.useState(utils.isDevelopment() === true ? '10分' : '')
  const [actionItems, setActionItems] = React.useState(defaultActionItems)
  const [goodsBaseItems, setGoodsBaseItems] = React.useState(defaultGoodsItems)
  const [goodsItems, setGoodsItems] = React.useState<DndListItemType[]>([])
  const [house, setHouse] = React.useState('')
  const [kawamiruWindow, setKawamiruWindow] = React.useState<Window | null>(null)
  const [riskLatLng, setRiskLatLng] = React.useState<[number, number] | null>(null)
  const [destinationLatLng, setDestinationLatLng] = React.useState<[number, number] | null>(null)

  // 洪水浸水想定区域のリスク値
  const getSinsuiRiskValue = (): string => {
    if (risks === undefined) {
      return ''
    }
    const finded = risks.find(r => r.value !== '' && r.name.startsWith('洪水浸水想定区域'))
    return finded !== undefined ? finded.value : ''
  }

  // 洪水浸水想定区域のリスクレベル
  const getSinsuiRiskLevel = (): number => {
    const value = getSinsuiRiskValue()
    if (value === '') {
      return 0
    } else if (value === '~0.5m') {
      return 1
    } else if (value === '0.5m~3.0m') {
      return 2
    } else if (value === '3.0m~5.0m') {
      return 3
    } else if (value === '5.0m~10.0m') {
      return 4
    } else if (value === '10.0m~20.0m') {
      return 5
    } else {
      return 6
    }
  }

  // 浸水継続時間
  const getSinsuiRiskTime = (): string => {
    if (risks === undefined) {
      return ''
    }
    const finded = risks.find(r => r.name.startsWith('浸水継続時間') && r.value !== '')
    if (finded !== undefined) {
      return finded.value
    } else {
      return ''
    }
  }

  // リスクの有無
  const hasRisk = (): boolean => {
    if (risks === undefined) {
      return false
    }
    // 浸水継続時間はリスク判定から除外
    const finded = risks.find(r => r.value !== '' && !r.name.startsWith('浸水継続時間'))
    return finded !== undefined
  }

  // 家屋倒壊リスクの有無
  const hasKaokutoukaiRisk = (): boolean => {
    if (risks === undefined) {
      return false
    }
    const finded = risks.find(r => r.value !== '' && r.name.startsWith('家屋倒壊'))
    return finded !== undefined
  }

  // 土砂災害警戒区域 0: 区域外, 1: 警戒区域, 2: 特別警戒区域
  const getDosyaWarningLevel = (): number => {
    if (risks === undefined) {
      return 0
    }
    let finded = risks.find(r => r.value === '特別警戒区域' && r.name.startsWith('土砂災害'))
    if (finded !== undefined) {
      return 2
    }
    finded = risks.find(r => r.value === '警戒区域' && r.name.startsWith('土砂災害'))
    if (finded !== undefined) {
      return 1
    } else {
      return 0
    }
  }

  // 避難レベル
  const getRiskLevel = (): number => {
    // リスクなし
    if (hasRisk() === false) {
      return 0
    }
    // 家屋倒壊、土砂災害
    if (hasKaokutoukaiRisk() === true || getDosyaWarningLevel() > 0) {
      if (isWithOthers) {
        return 3
      } else {
        return 4
      }
    }
    // 浸水
    if (isPatience === true) {
      const sinsui = getSinsuiRiskValue()
      if (sinsui === '~0.5m') {
        return 0
      } else if (sinsui === '0.5m~3.0m' && floor >= 2) {
        return 0
      } else if (sinsui === '3.0m~5.0m' && floor >= 3) {
        return 0
      }
    }
    if (isWithOthers) {
      return 3
    } else {
      return 4
    }
  }

  const setTestRisks = (): void => {
    setRiskLatLng([36.361056, 139.04535])
    setRisks([
      {
        'name': '洪水浸水想定区域(想定最大)',
        // 'value': '20.0m~'
        // 'value': '10.0m~20.0m'
        // 'value': '5.0m~10.0m'
        // 'value': '3.0m~5.0m'
        // 'value': '0.5m~3.0m'
        'value': '~0.5m'
      },
      {
        'name': '浸水継続時間(想定最大)',
        'value': '12時間未満',
        // 'value': '12時間〜1日未満',
        // 'value': '1日〜3日未満',
        // 'value': '3日〜1週間未満',
        // 'value': '1週間〜2週間未満',
        // 'value': '2週間〜4週間未満',
        // 'value': '4週間以上〜'
      },
      // {
      //   'name': '土砂災害警戒区域等・急傾斜地の崩壊',
      //   'value': '特別警戒区域'
      // },
      // {
      //   'name': '土砂災害警戒区域等・土石流',
      //   'value': '警戒区域'
      // },
      // {
      //   'name': '土砂災害警戒区域等・地すべり',
      //   'value': ''
      // },
      // {
      //   'name': '家屋倒壊等氾濫想定区域・氾濫流',
      //   'value': 'リスクあり'
      // },
      {
        'name': '家屋倒壊等氾濫想定区域・河岸侵食',
        'value': ''
      }
    ])
  }

  const value = React.useMemo(() => ({
    city, setCity,
    area, setArea,
    cityAreaCounter, setCityAreaCounter,
    risks, setRisks,
    floor, setFloor,
    isPatience, setIsPatience,
    isWithOthers, setIsWithOthers,
    othersItems, setOthersItems,
    isSafePlace, setIsSafePlace,
    observatories, setObservatories,
    observatory, setObservatory,
    destination, setDestination,
    destWay, setDestWay,
    destTime, setDestTime,
    house, setHouse,
    actionItems, setActionItems,
    goodsBaseItems, setGoodsBaseItems,
    goodsItems, setGoodsItems,
    kawamiruWindow, setKawamiruWindow,
    riskLatLng, setRiskLatLng,
    destinationLatLng, setDestinationLatLng,
    getSinsuiRiskValue,
    getSinsuiRiskLevel,
    getSinsuiRiskTime,
    hasRisk,
    hasKaokutoukaiRisk,
    getDosyaWarningLevel,
    getRiskLevel,
    setTestRisks
  }), [
    city, setCity,
    area, setArea,
    cityAreaCounter, setCityAreaCounter,
    risks, setRisks,
    floor, setFloor,
    isPatience, setIsPatience,
    isWithOthers, setIsWithOthers,
    othersItems, setOthersItems,
    isSafePlace, setIsSafePlace,
    observatories, setObservatories,
    observatory, setObservatory,
    destination, setDestination,
    destWay, setDestWay,
    destTime, setDestTime,
    house, setHouse,
    actionItems, setActionItems,
    goodsBaseItems, setGoodsBaseItems,
    goodsItems, setGoodsItems,
    kawamiruWindow, setKawamiruWindow,
    riskLatLng, setRiskLatLng,
    destinationLatLng, setDestinationLatLng,
    getSinsuiRiskValue,
    getSinsuiRiskLevel,
    getSinsuiRiskTime,
    hasRisk,
    hasKaokutoukaiRisk,
    getDosyaWarningLevel,
    getRiskLevel,
    setTestRisks
  ])

  return (
    <StateContext.Provider value={value}>
      {children}
    </StateContext.Provider>
  )
}

export function useStateContext() {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('Context is undefined')
  }
  return context
}
