import React from 'react'
import { Typography, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ActionButton } from 'components/ActionButton'
import { getUA } from 'react-device-detect'


export const Top = () => {
  const navigate = useNavigate()

  const tsulunosUrl = 'https://tsulunos.jp/single.cgi?id=1403'

  const navNext = () => {
    if (getUA.includes('Line')) {
      window.open('/risk-area?openExternalBrowser=1', '_blank')
      return
    } else {
      navigate('/risk-area')
    }
  }

  return (
    <Box sx={{padding: '0 15px', textAlign: 'center'}}>
      <Typography sx={{color: '#333333', fontWeight: 'bold', fontSize: '16px'}}>
        マイ・タイムラインとは
      </Typography>
      <Typography sx={{color: '#666666', fontSize: '12px', paddingBottom: '10px'}}>
        {'(個人の避難行動計画)'}
      </Typography>
      <Box sx={{padding: '0 15px', textAlign: 'left'}}>
        <Typography sx={{color: '#333333', fontSize: '16px', paddingBottom: '10px'}}>
        「マイ・タイムライン」は、台風の接近などにより、水害や土砂災害のおそれがある場合に家族構成や生活環境に合わせ、いつ、何をするのかをあらかじめ時系列で整理する住民一人ひとりの避難行動計画です。
        </Typography>
        <Typography sx={{color: '#333333', fontSize: '16px', paddingBottom: '10px'}}>
        あらかじめマイ・タイムラインをつくっておくことで、いざという時に落ち着いて行動することができ、自分や家族の命を守ることにつながります。
        </Typography>
        <Typography sx={{color: '#333333', fontSize: '16px', paddingBottom: '10px'}}>
        まだ、マイ・タイムラインをお持ちでない皆さん、ぜひつくってみてください！
        </Typography>
        <Typography sx={{color: '#333333', fontSize: '16px', paddingBottom: '10px'}}>
        （所要時間10分）
        </Typography>
      </Box>
      <ActionButton text={'WEBでマイ・タイムラインを'} text2="作成する" color='primary' onClick={navNext} mb={3} />
      <ActionButton text={'マイ・タイムライン作成紹介動画'} text2={'(YouTube)'} color='primary' onClick={() => window.open(tsulunosUrl, '_blank')} />
    </Box>
  )
}
