import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { useStateContext } from 'provider/StateProvider'
import { Title } from 'components/Title'
import { Timeline } from 'components/Timeline'
import { ActionButton } from 'components/ActionButton'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { AppendItem } from 'components/AppendItem'
import { DndContainer } from 'components/DndContainer'
import { DndListItemType } from 'common/types'
import DownArrow from 'assets/img/down_arrow.png'
import { Space } from 'components/Space'


export const ActionGoods = () => {
  const navigate = useNavigate()
  const state = useStateContext()

  React.useEffect(() => {
    if (state.area === '' || state.city === '') {
      navigate('/')
    }

    const arr = [...state.goodsBaseItems]
    const ids = state.goodsBaseItems.map(i => i.id).concat(state.goodsItems.map(i => i.id))
    let id = ids.length === 0 ? 0 : Math.max(...ids)
    const appendWithOtherItem = (text: string) => {
      const has = state.goodsBaseItems.some(i => i.text === text) || state.goodsItems.some(i => i.text === text)
      if (!has) {
        id++
        arr.push({
          category: 'goods',
          risk: 0,
          id: id,
          text: text
        })
      }
    }
    if (state.othersItems.includes('高齢者')) {
      appendWithOtherItem('おむつ')
      appendWithOtherItem('やわらかい食事')
    }
    if (state.othersItems.includes('障がい者')) {
      appendWithOtherItem('障害者手帳')
      appendWithOtherItem('特性に応じた補助具（補聴器、松葉杖、医療機器等）')
    }
    if (state.othersItems.includes('乳児・幼児')) {
      appendWithOtherItem('おむつ・おしりふき')
      appendWithOtherItem('哺乳瓶・粉ミルク')
      appendWithOtherItem('離乳食')
    }
    if (state.othersItems.includes('妊婦')) {
      appendWithOtherItem('妊産婦用衣類')
    }
    if (state.othersItems.includes('ペット')) {
      appendWithOtherItem('リード')
      appendWithOtherItem('ケージ')
      appendWithOtherItem('ペット用食料')
      appendWithOtherItem('ペットシーツ')
    }
    state.setGoodsBaseItems(arr)
  }, [])

  const appendItem = (text: string) => {
    const arr = [...state.goodsBaseItems]
    const ids = state.goodsBaseItems.map(i => i.id).concat(state.goodsItems.map(i => i.id))
    arr.push({
      category: 'goods',
      risk: 0,
      id: ids.length === 0 ? 1 : Math.max(...ids) + 1,
      text: text
    })
    state.setGoodsBaseItems(arr)
  }

  const deleteItem = (item: DndListItemType) => {
    if (item.risk === 1) {
      const items = state.goodsItems.filter(i => i.id !== item.id)
      state.setGoodsItems(items)
    } else {
      const items = state.goodsBaseItems.filter(i => i.id !== item.id)
      state.setGoodsBaseItems(items)
    }
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !result.source) {
      return
    }
    const base = [...state.goodsBaseItems]
    const goods = [...state.goodsItems]

    const src = result.source.droppableId === 'dnd-goods-base' ? base : goods
    const [removed] = src.splice(result.source.index, 1)

    const dest = result.destination.droppableId === 'dnd-goods-base' ? base : goods
    dest.splice(result.destination.index, 0, removed)

    base.forEach(r => r.risk = 0)
    goods.forEach(r => r.risk = 1)

    state.setGoodsBaseItems(base)
    state.setGoodsItems(goods)
  }

  return (
    <div>
      <Title text='避難行動の確認' />
      <Timeline process={3} />

      <Typography sx={{fontSize: '14px', fontWeight: 'bold'}} mb={2}>
        {'避難先への持ち物を追加しましょう'}
      </Typography>

      <AppendItem
        maxLength={15}
        disabled={state.goodsItems.length > 9}
        appended={appendItem}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <Box sx={{padding: 1, border: '1px solid #aaaaaa'}} mt={1}>
          <DndContainer droppableId='dnd-goods-base' isUp={false} isDown={true} deleted={deleteItem} canEdit={true} items={state.goodsBaseItems} />
        </Box>
        <Space height={10} />
        <img src={DownArrow} height={50} />
        <Box sx={{padding: 1, border: '1px solid #aaaaaa'}} mt={1} textAlign="left">
          <Typography fontWeight="bold">{'避難先への持ち物'}</Typography>
          <DndContainer droppableId='dnd-goods' isUp={true} isDown={true} deleted={deleteItem} canEdit={true} items={state.goodsItems} />
        </Box>
      </DragDropContext>

      <ActionButton text='次へ' color='primary' onClick={() => navigate('/action-case')} mt={5} />
      <ActionButton text='戻る' color='secondary' onClick={() => navigate(-1)} />
    </div>
  )
}
