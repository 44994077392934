import React from 'react'
import Button from '@mui/material/Button'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Box, CircularProgress } from '@mui/material'

type ActionButtonProps = {
  text: string,
  text2?: string,
  color: 'primary' | 'secondary',
  disabled?: boolean,
  loading?: boolean,
  mt?: number,
  mb?: number,
  onClick?: () => void
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#23cd63',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#dddddd',
      contrastText: '#666666'
    }
  },
  typography: {
    button: {
      fontWeight: 600
    }
  }
})

export const ActionButton = ({text, color, text2 = '', disabled = false, loading = false, mt=1, mb=1, onClick}: ActionButtonProps) => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ position: 'relative', mt: mt, mb: mb }}>
        <Button
          size="large"
          color={color}
          variant='contained'
          style={{maxWidth: '260px', minWidth: '260px', textTransform: 'none', padding: '8px 0'}}
          disabled={disabled}
          onClick={onClick}>
          {text}
          {text2 ?  <><br />{text2}</> : ''}
        </Button>
        { loading && <CircularProgress
          size={20}
          sx={{
            color: 'white',
            position: 'absolute',
            top: '50%',
            left: '28%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
        }
      </Box>
    </ThemeProvider>
  )
}
