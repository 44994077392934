import React from 'react'
import { Paper, Typography } from '@mui/material'


type RiskLabelProps = {
  level: number
  height?: string
  mx?: string | number
}

export const RiskLabel = ({level, height='auto', mx = undefined}: RiskLabelProps) => {
  const color = [1, 2].includes(level) ? '#666666' : 'white'
  let backgroundColor = 'white'
  if (level === 2) {
    backgroundColor = 'rgb(242,231,0)'
  } else if (level === 3) {
    backgroundColor = 'rgb(255,40,0)'
  } else if (level === 4) {
    backgroundColor = 'rgb(170,0,170)'
  } else if (level === 5) {
    backgroundColor = 'rgb(12,0,12)'
  }

  const zenkaku = (num: number) => {
    return String.fromCharCode(String(num).charCodeAt(0) + 0xFEE0)
  }

  return (
    <Paper variant='outlined' sx={{ minWidth: 140, width: 140, height: height, mx: mx, backgroundColor: backgroundColor }}>
      <Typography variant='body1' fontWeight={'bold'} color={color} sx={{lineHeight: height ,textAlign: 'center'}}>
        警戒レベル{zenkaku(level)}
      </Typography>
    </Paper>
  )
}
