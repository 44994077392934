import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Select, Typography, FormControlLabel, Radio, FormControl, MenuItem, InputLabel, RadioGroup } from '@mui/material'
import { useStateContext } from 'provider/StateProvider'
import { Title } from 'components/Title'
import { Timeline } from 'components/Timeline'
import { ActionButton } from 'components/ActionButton'
import { Question } from 'components/Question'


export const DestFlood = () => {
  const state = useStateContext()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (state.area === '' || state.city === '') {
      navigate('/')
    } else if (state.risks === undefined) {
      navigate('/risk-map')
    }
  }, [])

  const sinsuiTime = state.getSinsuiRiskTime()

  const goNext = (): void => {
    if (state.getRiskLevel() === 0) {
      navigate('/dest-confirm')
    } else {
      navigate('/dest-place')
    }
  }

  const getSinsuiTimeColor = (): string => {
    if (sinsuiTime === '4週間以上〜') {
      return 'rgb(96, 0, 96)'
    } else if (sinsuiTime === '2週間〜4週間未満') {
      return 'rgb(180, 0, 104)'
    } else if (sinsuiTime === '1週間〜2週間未満') {
      return 'rgb(255, 40, 0)'
    } else if (sinsuiTime === '3日〜1週間未満') {
      return 'rgb(255, 153, 0)'
    } else if (sinsuiTime === '1日〜3日未満') {
      return 'rgb(250, 245, 0)'
    } else if (sinsuiTime === '12時間〜1日未満') {
      return 'rgb(0, 65, 255)'
    } else if (sinsuiTime === '12時間未満') {
      return 'rgb(160, 210, 255)'
    } else {
      return 'rgb(240, 240, 240)'
    }
  }

  const textShadow = '1px 1px 0 #666, -1px -1px 0 #666, -1px 1px 0 #666, 1px -1px 0 #666, 0px 1px 0 #666,  0-1px 0 #666'

  return (
    <div>
      <Title text='家庭状況の確認' />
      <Timeline process={2} />
      <div>自宅の高さは何階ですか？</div>
      <FormControl sx={{ mt: 3, minWidth: 250 }}>
        <InputLabel id="floor-label">階数</InputLabel>
        <Select
          labelId="floor-label"
          value={state.floor}
          label="階数"
          onChange={(e) => {state.setFloor(Number(e.target.value))}}
        >
          <MenuItem disabled value={0}>
            <em>階数</em>
          </MenuItem>
          <MenuItem value={3}>3階以上</MenuItem>
          <MenuItem value={2}>2階</MenuItem>
          <MenuItem value={1}>1階</MenuItem>
        </Select>
      </FormControl>

      <Typography sx={{ fontSize: 16, fontWeight: 'bold', mt: 2, mb: 1 }}>
        あなたの家の浸水継続時間
      </Typography>
      <Card sx={{ minWidth: 260, width: 260, mx: 'auto', mb: 1, backgroundColor: getSinsuiTimeColor(), p: 1 }}>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold', textShadow: textShadow }} color="white">
          { sinsuiTime ? sinsuiTime : 'なし' }
        </Typography>
      </Card>

      <Question text={'水が引くまで我慢できますか？\n水・食料等の備えは十分用意できますか？'} />

      <FormControl sx={{mb: 3}}>
        <RadioGroup
          row
          value={state.isPatience ? '1' : '0'}
          onChange={(e) => state.setIsPatience(e.target.value === '1')}
        >
          <FormControlLabel value="1" control={<Radio />} label="はい" />
          <FormControlLabel value="0" control={<Radio />} label="いいえ" />
        </RadioGroup>
      </FormControl>

      <div>
        <ActionButton text='次へ' color='primary' disabled={ state.floor === 0 } onClick={goNext} />
        <ActionButton text='戻る' color='secondary' onClick={() => navigate(-1)} />
      </div>
    </div>
  )
}
