import React from 'react'
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, ListSubheader } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Title } from 'components/Title'
import { Timeline } from 'components/Timeline'
import { ActionButton } from 'components/ActionButton'
import { useStateContext } from 'provider/StateProvider'
import * as utils from 'common/utils'


export const RiskArea = () => {
  const state = useStateContext()
  const navigate = useNavigate()
  const cities = utils.getCities()
  const [areas, setAreas] = React.useState<string[]>([])

  React.useEffect(() => {
    let areaItems: string[] = []
    const targetAreas = utils.getAreas(state.city)
    if (targetAreas.length < 30) {
      areaItems = targetAreas.map(r => r.name)
    } else {
      const kanas = Array.from(new Set(targetAreas.map(r => r.kana[0]))).sort()
      kanas.forEach(kana => {
        areaItems.push(`ー ${kana}行 ー`)
        targetAreas.filter(r => r.kana[0] === kana).forEach(r => {
          areaItems.push(r.name)
        })
      })
    }
    setAreas(areaItems)
  }, [state.city])

  const changeCity = (e: SelectChangeEvent) => {
    const city = e.target.value
    state.setCity(city)
    state.setArea('')
  }

  return (
    <div>
      <Title text={'自宅の洪水・土砂災害リスクの確認'} />
      <Timeline process={1} />
      <div>お住まいの市町村・地区を</div>
      <div>選択してください</div>
      <FormControl sx={{ mt: 3, minWidth: 250 }}>
        <InputLabel id="city-label">市町村</InputLabel>
        <Select
          labelId="city-label"
          value={state.city}
          label="市町村"
          onChange={changeCity}
        >
          <MenuItem disabled value={''}><em>市町村</em></MenuItem>
          {
            cities.map((c: string, i: number) => <MenuItem key={i} value={c}>{c}</MenuItem>)
          }
        </Select>
      </FormControl>

      <FormControl sx={{ mt: 3, mb:5, minWidth: 250 }}>
        <InputLabel id="area-label">地区</InputLabel>
        <Select
          labelId="area-label"
          value={state.area}
          label="地区"
          onChange={e => state.setArea(e.target.value)}
        >
          {
            areas.map((area, i) =>
              area.startsWith('ー ') ? <ListSubheader key={i}>{area}</ListSubheader> : <MenuItem key={i} value={area}>{area}</MenuItem>
            )
          }
        </Select>
      </FormControl>
      <ActionButton text='次へ' color='primary' disabled={state.city === '' || state.area === ''} onClick={() => navigate('/risk-map')} />
    </div>
  )
}
