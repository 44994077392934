import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import axios from 'axios'
import cityAreaJson from 'assets/json/city_area.json'


interface CityAreaData {
  city: string
  area: string
  counter: number
  priority: number
}

export const CityAreaCounter = () => {
  const [rows, setRows] = React.useState<CityAreaData[]>([])

  React.useEffect(() => {
    (async() => {
      const baseurl = process.env.REACT_APP_API_URL
      const response = await axios.get(`${baseurl}/api/city-area-counter/scan`)
      if (response.data !== null && response.data.items) {
        const items: CityAreaData[] = response.data.items
        items.forEach(item => {
          item.priority = cityAreaJson.findIndex(({city, area}) => city === item.city && area === item.area)
          if (item.priority === -1) {
            item.priority = 9999
          }
        })
        items.sort((a, b) => a.priority - b.priority)
        setRows(items)
      } else {
        setRows([])
      }
    })()
  }, [])

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>市町村</TableCell>
            <TableCell>地域</TableCell>
            <TableCell align="right">作成回数</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.city}</TableCell>
              <TableCell>{row.area}</TableCell>
              <TableCell align="right">{row.counter}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
