import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from 'provider/StateProvider'
import { Title } from 'components/Title'
import { Timeline } from 'components/Timeline'
import { ActionButton } from 'components/ActionButton'
import { DndList } from 'components/DndList'
import { Box, Typography } from '@mui/material'
import { AppendItem } from 'components/AppendItem'


export const ActionCase = () => {
  const navigate = useNavigate()
  const state = useStateContext()

  React.useEffect(() => {
    if (state.area === '' || state.city === '') {
      navigate('/')
    }
  }, [])

  const appendItem = (text: string) => {
    const arr = [...state.actionItems]
    const ids = state.actionItems.map(i => i.id)
    arr.push({
      category: 'case',
      id: ids.length === 0 ? 1 : Math.max(...ids) + 1,
      text: text,
      risk: 0
    })
    state.setActionItems(arr)
  }

  return (
    <div>
      <Title text='避難行動の確認' />
      <Timeline process={3} />

      <Typography sx={{fontSize: '14px', fontWeight: 'bold'}} mb={2}>
        {'自分に必要な行動を考えて入力し、'}<br />{'自分に必要な行動リスト（例）の項目も合わせて、'}<br />{'警戒レベルごとに必要な順番に並べ替えてみましょう。'}
      </Typography>

      <AppendItem
        maxLength={20}
        disabled={state.actionItems.filter(item => item.category === 'case').length >= 10}
        appended={appendItem}
      />

      <Box textAlign={'right'} mb={2}>
        <Typography variant='caption' sx={{textAlign: 'right'}}>
          最大10項目
        </Typography>
      </Box>

      <DndList category='case' state={state} canEdit={true} canDelete={true} />

      <ActionButton text='次へ' color='primary' onClick={() => navigate('/action-observatory')} mt={5} />
      <ActionButton text='戻る' color='secondary' onClick={() => navigate(-1)} />
    </div>
  )
}
