import React from 'react'
import './HouseInput.scss'
import { FormControl, OutlinedInput, InputAdornment, IconButton } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

type AppendItemProps = {
  maxLength: number
  disabled?: boolean
  appended: (text: string) => void
}

export const AppendItem = ({maxLength, disabled=false, appended}: AppendItemProps) => {
  const [inputText, setInputText] = React.useState('')
  const onAppend = () => {
    if (!inputText) {
      return
    }
    appended(inputText)
    setInputText('')
  }
  return (
    <FormControl fullWidth>
      <OutlinedInput
        inputProps={{maxLength: maxLength}}
        sx={{backgroundColor: 'white', pr: 1}}
        fullWidth
        type={'text'}
        placeholder={`追加項目を入力 (${maxLength}文字以内)`}
        value={inputText}
        disabled={disabled}
        onChange={(e)=>setInputText(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={onAppend}
              onMouseDown={(e) => e.preventDefault()}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  )
}
