import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FormControlLabel, Button, Typography, Box, FormGroup, Checkbox } from '@mui/material'
import { useStateContext } from 'provider/StateProvider'
import { Title } from 'components/Title'
import { Timeline } from 'components/Timeline'
import { ActionButton } from 'components/ActionButton'
import { kawamirugunmaUrl } from 'common/consts'
import * as utils from 'common/utils'
import axios from 'axios'


export const ActionObservatory = () => {
  const navigate = useNavigate()
  const state = useStateContext()
  const selectedObservatories: string[] = React.useMemo(() => state.observatory === '' ? [] : state.observatory.split(','), [state.observatory])

  React.useEffect(() => {
    if (state.area === '' || state.city === '') {
      navigate('/')
    } else if (state.risks === undefined) {
      navigate('/risk-map')
    } else if (state.observatories.length === 0) {
      syncObservatories()
    }
    return () => {
      window.removeEventListener('message', loadObservatory)
    }
  }, [])

  const syncObservatories = async () => {
    const baseurl = process.env.REACT_APP_API_URL

    let query = '?lat=36.39004033&lng=139.0589418'
    if (state.riskLatLng !== null) {
      query = `?lat=${state.riskLatLng[0]}&lng=${state.riskLatLng[1]}`
    } else {
      const latLng = utils.getLatLng(state.city, state.area)
      if (latLng !== undefined) {
        query = `?lat=${latLng.lat}&lng=${latLng.lng}`
      }
    }
    const res = await axios.get(`${baseurl}/api/observatories/near${query}`)
    if (res !== null && res.data.observatories) {
      state.setObservatories(res.data.observatories)
    }
  }

  const isNextDisabled = (): boolean => {
    return selectedObservatories.length === 0
  }

  const deleteObservatory = () => {
    if (selectedObservatories.length === 0) {
      return
    }
    const arr = [...state.observatories].filter(obs => selectedObservatories.includes(obs) === false)
    state.setObservatories(arr)
    state.setObservatory('')
  }

  const loadObservatory = (e: MessageEvent) => {
    if (utils.isDevelopment() === true) {
      return
    }
    if (e.origin !== kawamirugunmaUrl) {
      return
    }
    if (e.data) {
      const arr = [...state.observatories]
      if (arr.includes(e.data) === false) {
        state.setObservatories(arr.concat(e.data))
      }
      if (selectedObservatories.includes(e.data) === false) {
        const arr = [...selectedObservatories].concat(e.data)
        state.setObservatory(arr.join(','))
      }
    }
  }

  React.useEffect(() => {
    window.addEventListener('message', loadObservatory)
    return () => {
      window.removeEventListener('message', loadObservatory)
    }
  }, [loadObservatory])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value
    const arr = [...selectedObservatories]
    if (arr.includes(val) === true) {
      state.setObservatory(arr.filter(r => r !== val).join(','))
    } else {
      state.setObservatory(arr.concat(val).join(','))
    }
  }

  const showKawamiru = () => {
    if (utils.isDevelopment() === true) {
      const arr = [...state.observatories]
      state.setObservatories(
        arr
          .concat('鏑川３１．５ｋ下仁田高校グラウンド')
          .concat('新谷田川放水路０．５ｋ利根加用水交差付近')
          .concat('邑楽東部第二排水機場 大箇野・海老瀬川内水位')
      )
      return
    }
    let gps = '&lat=36.3912157951864&lng=139.060971736908'
    if (state.riskLatLng !== null) {
      gps = `&lat=${state.riskLatLng[0]}&lng=${state.riskLatLng[1]}`
    } else {
      const latLng = utils.getLatLng(state.city, state.area)
      if (latLng !== undefined) {
        gps = `&lat=${latLng.lat}&lng=${latLng.lng}`
      }
    }

    const url = `${kawamirugunmaUrl}?openExternalBrowser=1&zoom=15&risk=observatory&tile=gray&observatory=4&mytimeline_observatory=1${gps}`
    const openUrl = (url: string) => {
      const w = window.open(url)
      if (w !== null) {
        state.setKawamiruWindow(w)
      }
    }
    if (state.kawamiruWindow === null || state.kawamiruWindow.closed === true) {
      openUrl(url)
    } else {
      state.kawamiruWindow.close()
      setTimeout(() => {
        openUrl(url)
      }, 500)
    }
  }

  return (
    <div>
      <Title text='避難行動の確認' />
      <Timeline process={3} />

      <Typography sx={{fontSize: '14px', fontWeight: 'bold'}} mb={3}>
        {'見るべき水位観測所を決めましょう'}
      </Typography>

      <Typography variant='body2'>
        {'自宅周辺の水位観測所'}
      </Typography>

      <FormGroup sx={{px: 3}}>
        {
          state.observatories.map((obs, i) => {
            return (
              <FormControlLabel
                label={obs}
                key={i}
                sx={{'& .MuiSvgIcon-root': {
                  fontSize: 20,
                }}}
                control={
                  <Checkbox
                    value={obs}
                    checked={selectedObservatories.includes(obs)}
                    onChange={handleCheckboxChange}
                  />
                }
              />
            )
          })
        }
      </FormGroup>

      <Box sx={{mx: 'auto', mb: 4}}>
        <Button color='error' size='small' variant='text' onClick={deleteObservatory}>選択中の観測所を削除する</Button>
      </Box>

      <Box sx={{width: 260, mx: 'auto', mb: 4}}>
        <Button variant='outlined' onClick={showKawamiru}>地図（かわみるぐんま）から<br />観測所を選択する</Button>
      </Box>

      <div>
        <ActionButton text='次へ' color='primary' disabled={isNextDisabled()} onClick={() => navigate('/timeline-confirm')} />
        <ActionButton text='戻る' color='secondary' onClick={() => navigate(-1)} />
      </div>
    </div>
  )
}
