import React from 'react'
import './Title.scss'

type TitleProps = {
  text: string
}

export const Title = ({text}: TitleProps) => {
  return (
    <div className='title'>
      <h2><span>{ text }</span></h2>
    </div>
  )
}
