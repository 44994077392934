import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from 'provider/StateProvider'
import { Title } from 'components/Title'
import { Timeline } from 'components/Timeline'
import { ActionButton } from 'components/ActionButton'
import { Typography } from '@mui/material'
import { DndList } from 'components/DndList'


export const ActionBase = () => {
  const navigate = useNavigate()
  const state = useStateContext()

  React.useEffect(() => {
    if (state.area === '' || state.city === '') {
      navigate('/')
    }
  }, [])

  return (
    <div>
      <Title text='避難行動の確認' />
      <Timeline process={3} />

      <Typography sx={{fontSize: '14px', fontWeight: 'bold'}} mb={2}>
        {'「必要な基本行動」を並べ替えてみましょう。'}
      </Typography>

      <DndList category='base' state={state} canEdit={true} canDelete={false} />

      <ActionButton text='次へ' color='primary' onClick={() => navigate('/action-goods')} mt={5} />
      <ActionButton text='戻る' color='secondary' onClick={() => navigate(-1)} />
    </div>
  )
}
