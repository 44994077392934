import React from 'react'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { DndContainer } from './DndContainer'
import { StateContextType } from 'provider/StateProvider'
import { DndListItemType, ProgressCategoryType } from 'common/types'
import { RiskLabel } from './RiskLabel'
import DownArrow from 'assets/img/down_arrow.png'
import { Space } from './Space'


type DndListProps = {
  category?: ProgressCategoryType
  state: StateContextType
  canEdit: boolean
  canDelete: boolean
}

export const DndList = ({category=undefined, state, canEdit, canDelete}: DndListProps) => {
  const [items0, setItems0] = React.useState<DndListItemType[]>([])
  const [items1, setItems1] = React.useState<DndListItemType[]>([])
  const [items2, setItems2] = React.useState<DndListItemType[]>([])
  const [items3, setItems3] = React.useState<DndListItemType[]>([])
  const [items4, setItems4] = React.useState<DndListItemType[]>([])

  React.useEffect(() => {
    if (category === 'base') {
      const categoryItems = state.actionItems.filter(i => ['base', 'destination'].includes(i.category))
      setItems0(categoryItems.filter(i => i.category === 'base' && i.risk === 0))
      setItems1(categoryItems.filter(i => i.risk === 1))
      setItems2(categoryItems.filter(i => i.risk === 2))
      setItems3(categoryItems.filter(i => i.risk === 3))
      setItems4(categoryItems.filter(i => i.risk === 4))
    } else {
      if (category === 'case') {
        setItems0(state.actionItems.filter(i => i.category === category && i.risk === 0))
      } else {
        setItems0(state.actionItems.filter(i => i.risk === 0))
      }
      setItems1(state.actionItems.filter(i => i.risk === 1))
      setItems2(state.actionItems.filter(i => i.risk === 2))
      setItems3(state.actionItems.filter(i => i.risk === 3))
      setItems4(state.actionItems.filter(i => i.risk === 4))
    }
  }, [state.actionItems])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !result.source) {
      return
    }
    const risk0 = [...items0]
    const risk1 = [...items1]
    const risk2 = [...items2]
    const risk3 = [...items3]
    const risk4 = [...items4]

    const src = result.source.droppableId === 'risk1' ? risk1 :
      result.source.droppableId === 'risk2' ? risk2 :
        result.source.droppableId === 'risk3' ? risk3 :
          result.source.droppableId === 'risk4' ? risk4 :
            risk0

    if (src[result.source.index].category === 'destination' && result.destination.droppableId === 'risk0') {
      return
    }

    const [removed] = src.splice(result.source.index, 1)
    removed.risk = result.destination.droppableId === 'risk1' ? 1 :
      result.destination.droppableId === 'risk2' ? 2 :
        result.destination.droppableId === 'risk3' ? 3 :
          result.destination.droppableId === 'risk4' ? 4 :
            0

    const dest = result.destination.droppableId === 'risk1' ? risk1 :
      result.destination.droppableId === 'risk2' ? risk2 :
        result.destination.droppableId === 'risk3' ? risk3 :
          result.destination.droppableId === 'risk4' ? risk4 :
            risk0
    dest.splice(result.destination.index, 0, removed)

    setItems0(risk0)
    setItems1(risk1)
    setItems2(risk2)
    setItems3(risk3)
    setItems3(risk4)
    sync(risk0, risk1, risk2, risk3, risk4)
  }

  const deleteItem = (item: DndListItemType) => {
    const items = state.actionItems.filter(i => i.id !== item.id)
    state.setActionItems(items)
  }

  const sync = (risk0: DndListItemType[], risk1: DndListItemType[], risk2: DndListItemType[], risk3: DndListItemType[], risk4: DndListItemType[]) => {
    const items = [...risk0].concat([...risk1]).concat([...risk2]).concat([...risk3].concat([...risk4]))
    if (category === 'base') {
      const arr = state.actionItems.filter(i => !['base', 'destination'].includes(i.category))
      state.setActionItems(items.concat(arr))
    } else if (category === 'case') {
      const arr = state.actionItems.filter(i => i.category !== category && i.risk === 0)
      state.setActionItems(arr.concat(items))
    } else {
      state.setActionItems(items)
    }
  }

  const ProcessBox = ({ level, caption, title, children }: {
    level?: number
    caption?: string
    title?: string
    children?: React.ReactNode;
  }) => {
    let backgrandColor = 'transparent'
    if (!category) {
      if (level === 1) {
        backgrandColor = '#fafafa'
      } else if (level === 2) {
        backgrandColor = '#fdf9da'
      } else if (level === 3) {
        backgrandColor = '#fcc3ac'
      } else if (level === 4) {
        backgrandColor = '#f3c0de'
      } else if (level === 5) {
        backgrandColor = '#cccccc'
      }
    }
    return (
      <Box sx={{padding: 1, border: '1px solid #aaaaaa', backgroundColor: backgrandColor}} textAlign="left" mt={1}>
        { level && <RiskLabel level={level}></RiskLabel> }
        { caption && <Typography variant='caption'>{caption}</Typography> }
        { title && <Typography fontWeight="bold">{title}</Typography> }
        {children}
      </Box>
    )
  }

  const FixedItem = ({title}: {
    title: string
  }) => {
    return (
      <Paper
        variant='outlined'
        sx={{
          my: 1,
          pl: '10px',
          py: '10px',
          backgroundColor: 'transparent',
          color: '#000000',
          fontSize: '14px',
          border: '2px solid #000000'
        }}
      >
        {title}
      </Paper>
    )
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {
          category && (
            <>
              <ProcessBox title={category === 'base' ? '基本的に必要な行動リスト' : '自分に必要な行動リスト（例）'}>
                <DndContainer droppableId='risk0' items={items0} isUp={false} isDown={true} deleted={canDelete ? deleteItem : undefined} canEdit={canEdit} />
              </ProcessBox>
              <Space height={10} />
              <Stack>緑枠の項目は動かすことができます。</Stack>
              <Space height={10} />
              <img src={DownArrow} height={50} />
            </>
          )
        }

        <ProcessBox level={1} caption='災害発生までのおおよその時期' title='3日〜1日前'>
          <DndContainer droppableId='risk1' items={items1} isUp={true} isDown={true} deleted={canDelete ? deleteItem : undefined} canEdit={canEdit} />
        </ProcessBox>
        <ProcessBox level={2} caption='災害発生までのおおよその時期' title='半日〜避難直前'>
          <DndContainer droppableId='risk2' items={items2} isUp={true} isDown={true} deleted={canDelete ? deleteItem : undefined} canEdit={canEdit} />
        </ProcessBox>
        <ProcessBox level={3} title='高齢者等避難が発令されたら'>
          <DndContainer droppableId='risk3' items={items3} isUp={true} isDown={true} deleted={canDelete ? deleteItem : undefined} canEdit={canEdit} />
        </ProcessBox>
        <ProcessBox level={4} title='避難指示が発令されたら'>
          <DndContainer droppableId='risk4' items={items4} isUp={true} isDown={true} deleted={canDelete ? deleteItem : undefined} canEdit={canEdit} />
        </ProcessBox>
      </DragDropContext>

      <ProcessBox level={5}>
        <FixedItem title='災害発生！' />
      </ProcessBox>
    </>
  )
}
