import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { DndListItemType } from 'common/types'
import { DndItem } from './DndItem'


type DndContainerProps = {
  droppableId: string
  items: DndListItemType[]
  isUp: boolean
  isDown: boolean
  canEdit?: boolean
  deleted?: (item: DndListItemType) => void
}

export const DndContainer = ({droppableId, items, isUp, isDown, canEdit=true, deleted=undefined}: DndContainerProps) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {items.map((item, index) => (
            <Draggable
              isDragDisabled={!canEdit}
              key={item.id}
              draggableId={`${droppableId}-${item.id}`}
              index={index}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <DndItem
                    text={item.text}
                    isUp={isUp && canEdit}
                    isDown={isDown && canEdit}
                    item={item}
                    isDragging={snapshot.isDragging}
                    canDelete={deleted !== undefined && ['goods', 'case'].includes(item.category)}
                    onDelete={() => { deleted && deleted(item) }}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
