import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, Paper } from '@mui/material'
import { useStateContext } from 'provider/StateProvider'
import { Title } from 'components/Title'
import { Timeline } from 'components/Timeline'
import { ActionButton } from 'components/ActionButton'
import * as utils from 'common/utils'


export const RiskConfirm = () => {
  const state = useStateContext()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (state.area === '' || state.city === '') {
      navigate('/')
    }
  }, [])

  const getRiskLabel = (title: string) => {
    const isDosya = title === '土砂災害'
    const contents: string[] = []
    if (isDosya) {
      const dosya = state.risks?.find(r => r.name.startsWith('土砂災害警戒区域') && r.value !== '')
      if (dosya !== undefined) {
        contents.push('土砂災害警戒区域: 区域内')
      }
    } else {
      state.risks?.forEach(c => {
        let name = c.name
        let value = c.value
        // 洪水浸水
        if ((name.startsWith('洪水浸水想定区域') || name.startsWith('浸水継続時間')) && value !== '') {
          if (name.startsWith('洪水浸水想定区域')) {
            name = name.replace('(想定最大)', '')
            value = utils.getSinsuiDisplayValue(value)
          }
          contents.push(name + ': ' + value)
        }
      })
      // 家屋倒壊等
      const kaoku = state.risks?.find(r => r.name.startsWith('家屋倒壊等氾濫想定区域') && r.value !== '')
      if (kaoku !== undefined) {
        contents.push('家屋倒壊等氾濫想定区域: 区域内')
      }
    }

    let content = contents.join('\n')
    if (contents.length === 0) {
      content = title === '土砂災害' ? '土砂災害警戒区域：区域外' : '洪水浸水想定区域：区域外'
    }
    return (
      <>
        <Paper variant='outlined' sx={{
          backgroundColor: isDosya ? '#f7c885' : '#b4e4f8',
          color: isDosya ? '#cb821a' : '#37a9d8',
          fontSize: 18,
          fontWeight: 'bold',
          padding: 1,
          mt: 3
        }}>
          { title }
        </Paper>
        <Typography sx={{ fontSize: 14, textAlign: 'left', whiteSpace: 'pre-wrap', mt: 1 }} color="text.secondary">
          { content }
        </Typography>
      </>
    )
  }

  const goNext = (): void => {
    const sinsuiRiskLevel = state.getSinsuiRiskLevel()
    // 家屋倒壊、土砂災害優先
    if (state.hasKaokutoukaiRisk() === true || state.getDosyaWarningLevel() > 0 || sinsuiRiskLevel >= 4) {
      navigate('/dest-place')
    } else if (sinsuiRiskLevel > 0) {
      navigate('/dest-flood')
    } else if (state.hasRisk() === true) {
      navigate('/dest-place')
    } else {
      navigate('/dest-confirm')
    }
  }

  return (
    <div>
      <Title text='自宅の洪水・土砂災害リスクの確認' />
      <Timeline process={1} />

      { 'あなたの自宅の洪水・土砂災害リスク' }
      { getRiskLabel('洪水') }
      { getRiskLabel('土砂災害') }

      <ActionButton text='次へ' color='primary' mt={3} onClick={goNext} />
      <ActionButton text='戻る' color='secondary' onClick={() => navigate(-1)} />
    </div>
  )
}
