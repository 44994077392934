import React from 'react'
import { Step, StepLabel, Stepper, createTheme, ThemeProvider } from '@mui/material'

type TimelineProps = {
  process: number
}

const steps = [
  'ｽﾃｯﾌﾟ1',
  'ｽﾃｯﾌﾟ2',
  'ｽﾃｯﾌﾟ3',
  'ｽﾃｯﾌﾟ4'
]

const theme = createTheme({
  typography: {fontSize: 14},
  palette: {
    primary: {
      main: '#23cd63',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#dddddd',
      contrastText: '#666666'
    }
  },
})

export const Timeline = ({process}: TimelineProps) => {
  return (
    <ThemeProvider theme={theme}>
      <Stepper activeStep={process-1} alternativeLabel sx={{my: 4}}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </ThemeProvider>
  )
}
